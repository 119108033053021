<template>
  <div id="signup-view">
    <b-overlay>
      <section class="bg-light ftco-section">
        <div class="d-flex justify-content-center">
          <img src="@/assets/site/images/loader.gif" v-if="loader===true" class="img-fluid  loader-width" alt />
        </div>
        <div class="container" v-if="! loader">
          <div class="row flex-lg-row flex-md-row">
            <div class="col-md-5">
              <div style="margin-top: 2rem">
                <div class="card-body bg-dark-gray">
                  <h2 class="text-black">Sign up</h2>
                  <p class="text3" style="font-size: 12px">
                    Aloha, welcome to CalMed Hawaii!
                  </p>
                  <form>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="first_name" placeholder="First Name*" />
                      <span class="text-danger" v-if="errors.first_name">{{
                        this.errors.first_name[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="last_name" placeholder="Last Name*" />
                      <span class="text-danger" v-if="errors.last_name">{{
                        this.errors.last_name[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input type="email" class="form-control" v-model="email" placeholder="Email Address*" />
                      <span class="text-danger" v-if="errors.email">{{
                        this.errors.email[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control" v-model="password" placeholder="Password*" />
                      <span class="text-danger" v-if="errors.password">{{
                        this.errors.password[0]
                      }}</span>
                    </div>
                     
                    <div class="form-group">
                      <input type="password" class="form-control" v-model="confirm_password"
                        placeholder="Confirm Password*" />
                      <span class="text-danger" v-if="errors.confirm_password">{{
                        this.errors.confirm_password[0]
                      }}</span>
                    </div>
                    <div class="row mb-1">
                      <!-- /.col -->
                      <div class="mt-4 mb-2 ml-3">
                        <button @click="register" type="button" class="btn btn-primary btn-block px-4">
                          Create Account
                        </button>
                      </div>
                    </div>
                    <p style="font-size: 14px">
                      Already have an account?
                      <router-link to="/site" exact>Login</router-link>
                    </p>
                    <!-- /.col -->
                  </form>
                </div>
                <!-- /.login-card-body -->
              </div>
            </div>
            <div class="col-md-7">
              <img src="@/assets/site/images/signup.png" alt="" class="img-fluid" />
            </div>
          </div>
        </div>
      </section>
    </b-overlay>
  </div>
</template>

<script>
  export default {
    name: "SignupView",
    data() {
      return {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        confirm_password: null,
        errors: [],
        loader: false,
      };
    },
    methods: {
      register() {
        //Save or update User Details
        this.errors = [];
        this.loader = true;
        let apiUrl = process.env.VUE_APP_URL + "api/register";
        let method_type = "post";
        fetch(apiUrl, {
          method: method_type,
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            confirm_password: this.confirm_password,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            if (data.status === true) {
              this.resetForm();
              var userData = {
                user: data.user,
                token: data.token,
                status: true
              };
              localStorage.setItem("userData", JSON.stringify(userData));
              var sessionId = JSON.parse(localStorage.getItem("sessionId"));
              if (sessionId) {
                this.signUpUserSession(sessionId);
              } else {
                window.location.href = "/user/dashboard";
              }
            } else if (data.status === false) {
              this.$swal("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function (err) {
            console.log("Register Data", "Error : " + err.message, "error");
          });
      },
      resetForm() {
        this.first_name = null;
        this.last_name = null;
        this.email = null;
        this.password = null;
        this.confirm_password = null;
        this.errors = [];
      },
      signUpUserSession(sessionId) {
        console.log("session store fn");
        this.loader = true;
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.token;
        var fetch_url = process.env.VUE_APP_URL + "api/session/signUpSession";
        fetch(fetch_url, {
          method: 'post',
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            session_id: sessionId,
            user_id: userdata_array.user.id,
          }),
        })
          .then(res => res.json())
          .then(data => {
            this.loader = false;
            if (data.status === true) {
              this.loader = false;
              this.$swal("Good to Go", data.message, "success");
              localStorage.removeItem("sessionId")
              // this.$router.push('/lactation_edn');
              window.location.href = "/lactation_edn";
            } else if (data.status === false) {
              this.loader = false;
              this.$swal("Error", data.message, "error");
              localStorage.removeItem("sessionId")
              // this.$router.push('/lactation_edn');
              window.location.href = "/lactation_edn";
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function (err) {
            console.log("Fetch Error :-S", err);
          });
      },
    },
  };
</script>